import React from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import CookiesPolicy from "../components/terms/cookies-policy"
import { Header } from '../components/header'

const Cookies = () => {

    return (
        <>
            <Helmet>
                <title>GoApps&trade; - Cookies Policy</title>
                <meta name="description" content="About cookies and how we use them" />
            </Helmet>
            <Layout>
                <Header />
                <Container className="withHeader">
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <h1 className="heading mb-half">Cookies Policy</h1>
                            <h2 className="headingSmall highlight">V2 - Last updated: August 2021</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} xl={9}>
                            <CookiesPolicy cssClass="legal" />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Cookies